<template>
    <div>
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/home' }"
                >首页</el-breadcrumb-item
            >
            <el-breadcrumb-item
                :to="{ path: '/raceManagement/camp/campListData' }"
                >营地管理</el-breadcrumb-item
            >
            <el-breadcrumb-item>咨询列表</el-breadcrumb-item>
        </el-breadcrumb>
        <el-page-header @back="goBack"></el-page-header>
        <el-card style="margin-top: 15px">
            <el-row class="title_row">
                <el-col>
                    <span>信息筛选</span>
                </el-col>
            </el-row>
            <el-form
                :model="queryForm"
                class="filter_Form"
                label-width="80px"
                ref="queryFormRef"
            >
                <el-row :gutter="50">
                    <el-col :span="6">
                        <el-form-item label="姓名" prop="name">
                            <el-input
                                v-model="queryForm.name"
                                placeholder="请输入"
                                clearable
                            ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="手机号" prop="phone">
                            <el-input
                                v-model="queryForm.phone"
                                placeholder="请输入"
                                clearable
                            ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="营地" prop="campId">
                            <el-select
                                placeholder="请选择"
                                style="width: 100%"
                                v-model="queryForm.campId"
                                clearable
                            >
                                <el-option
                                    v-for="item in campList"
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.id"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="客单状态" prop="guestStatus">
                            <el-select
                                placeholder="请选择"
                                style="width: 100%"
                                v-model="queryForm.guestStatus"
                                clearable
                            >
                                <el-option
                                    v-for="item in dict_guestStatus"
                                    :key="item.dictCode"
                                    :label="item.dictLabel"
                                    :value="item.dictValue"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <el-row :gutter="20" type="flex" justify="end">
                <el-col :span="2">
                    <el-button
                        type="primary"
                        icon="el-icon-search"
                        size="small"
                        style="width: 100%"
                        @click="getDataList"
                        >查询</el-button
                    >
                </el-col>
                <el-col :span="2">
                    <el-button
                        type="warning"
                        icon="el-icon-refresh-right"
                        size="small"
                        style="width: 100%"
                        @click="queryReset"
                        >重置</el-button
                    >
                </el-col>
            </el-row>
        </el-card>
        <el-card style="margin-top: 15px">
            <el-row class="title_row">
                <el-col :span="12">
                    <span>咨询列表</span>
                </el-col>
                <el-col :span="12">
                    <div style="text-align: right">
                        <el-button
                            type="success"
                            size="small"
                            @click="addInfoDialog = true"
                            icon="el-icon-circle-plus-outline"
                            >新增咨询</el-button
                        >
                    </div>
                </el-col>
            </el-row>
            <el-table
                header-cell-class-name="tableHeaderStyle"
                :data="dataList"
                border
                stripe
            >
                <el-table-column
                    align="center"
                    label="#"
                    type="index"
                    width="50px"
                ></el-table-column>
                <el-table-column
                    align="center"
                    label="姓名"
                    prop="name"
                    width="100px"
                ></el-table-column>
                <el-table-column
                    align="center"
                    label="账号"
                    prop="phone"
                    width="100px"
                ></el-table-column>
                <el-table-column
                    align="center"
                    label="地址"
                    prop="address"
                    width="200px"
                ></el-table-column>
                <el-table-column
                    align="center"
                    label="营地名称"
                    prop="campId"
                    :formatter="campNameFormatter"
                ></el-table-column>
                <el-table-column
                    align="center"
                    label="咨询内容"
                    show-overflow-tooltip
                    prop="content"
                ></el-table-column>
                <el-table-column
                    align="center"
                    label="咨询时间"
                    prop="createTime"
                ></el-table-column>
                <el-table-column align="center" label="客单状态" width="120px">
                    <template slot-scope="scope">
                        <el-tag v-if="scope.row.guestStatus === 1"
                            >咨询中</el-tag
                        >
                        <el-tag
                            v-else-if="scope.row.guestStatus === 2"
                            type="success"
                            >已报名</el-tag
                        >
                        <el-tag
                            v-else-if="scope.row.guestStatus === 3"
                            type="danger"
                            >不考虑</el-tag
                        >
                        <el-tag v-else>-</el-tag>
                    </template>
                </el-table-column>
                <el-table-column label="操作" width="140px">
                    <template scope="scope">
                        <el-button
                            type="text"
                            size="small"
                            @click="addGuestStatus(scope.row)"
                            >跟踪记录</el-button
                        >
                        <el-button
                            type="text"
                            size="small"
                            @click="editStateDetail(scope.row)"
                            >更改状态</el-button
                        >
                    </template>
                </el-table-column>
            </el-table>
            <!-- 分页 -->
            <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="queryForm.pageNum"
                :page-sizes="[10, 20, 40, 80]"
                :page-size="queryForm.pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total"
            ></el-pagination>
        </el-card>
        <el-dialog
            title="添加咨询信息"
            :visible.sync="addInfoDialog"
            width="50%"
        >
            <el-form
                :model="addInfoModel"
                label-width="100px"
                ref="addInfoFormRef"
                :rules="addInfoRules"
                style="width: 100%"
            >
                <el-row :gutter="5">
                    <el-col :span="12">
                        <el-form-item label="营员姓名" prop="name">
                            <el-input
                                v-model="addInfoModel.name"
                                placeholder="请输入"
                            ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="手机号" prop="phone">
                            <el-input
                                v-model="addInfoModel.phone"
                                placeholder="请输入"
                            ></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="5">
                    <el-col :span="12">
                        <el-form-item label="地址" prop="address">
                            <el-input
                                v-model="addInfoModel.address"
                                placeholder="请输入"
                            ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="营地" prop="campId">
                            <el-select
                                placeholder="请选择"
                                style="width: 100%"
                                v-model="addInfoModel.campId"
                                clearable
                            >
                                <el-option
                                    v-for="item in campList"
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.id"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="5">
                    <el-col :span="12">
                        <el-form-item label="咨询内容" prop="content">
                            <el-input
                                type="textarea"
                                v-model="addInfoModel.content"
                                placeholder="请输入"
                            ></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="addCampersClick"
                    >保 存</el-button
                >
            </span>
        </el-dialog>
        <el-dialog
            title="更改状态"
            :visible.sync="changeStateDialog"
            width="30%"
        >
            <el-form
                :model="stateModel"
                label-width="100px"
                ref="changeStateFormRef"
                :rules="changeStateRules"
                style="width: 100%"
            >
                <el-row :gutter="5">
                    <el-col :span="18">
                        <el-form-item label="状态" prop="guestStatus">
                            <el-select
                                placeholder="请选择"
                                style="width: 100%"
                                v-model="stateModel.guestStatus"
                                clearable
                            >
                                <el-option
                                    v-for="item in dict_guestStatus"
                                    :key="item.dictCode"
                                    :label="item.dictLabel"
                                    :value="item.dictValue"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="changeClicked"
                    >修改</el-button
                >
            </span>
        </el-dialog>
        <el-dialog
            title="咨询信息"
            :visible.sync="traceDialogVisable"
            width="60%"
        >
            <el-row class="title_row" align="middle" type="flex">
                <el-col :span="6">
                    <span class="title_class">跟踪记录</span>
                </el-col>
                <el-col :span="18" style="text-align: right">
                    <el-button
                        size="small"
                        type="success"
                        @click="addTraceRecord"
                        icon="el-icon-plus"
                        plain
                        >新增记录</el-button
                    >
                </el-col>
            </el-row>
            <el-table
                v-if="traceList.length > 0"
                :data="traceList"
                style="width: 100%"
                :row-class-name="tableRowClassName"
            >
                <el-table-column prop="name" label="联系人" width="180">
                </el-table-column>
                <el-table-column prop="happenTime" label="跟踪时间">
                </el-table-column>
                <el-table-column prop="content" label="内容"> </el-table-column>
                <el-table-column
                    prop="interviewMethod"
                    label="约谈方式"
                    :formatter="interviewMethodFormatter"
                >
                </el-table-column>
                <el-table-column label="操作" width="120px">
                    <template scope="scope">
                        <el-button
                            type="text"
                            size="small"
                            @click="editCampDetail(scope.row)"
                            >编辑</el-button
                        >
                        <el-button
                            type="text"
                            size="small"
                            style="color: red;"
                            @click="showDeleteCampTip(scope.row.id)"
                            >删除</el-button
                        >
                    </template>
                </el-table-column>
            </el-table>
            <div
                v-else
                style="
                    font-size: 12px;
                    color: gray;
                    text-align: center;
                    margin-top: 20px;
                    margin-bottom: 30px;
                "
            >
                暂无记录信息
            </div>
        </el-dialog>
        <el-dialog
            title="咨询信息"
            :visible.sync="addTraceDialogVisable"
            width="60%"
        >
            <el-row class="title_row" align="middle" type="flex">
                <el-col :span="6">
                    <span class="title_class">编辑记录</span>
                </el-col>
            </el-row>
            <el-form
                :model="traceForm"
                ref="traceFormRef"
                :rules="traceFormRules"
            >
                <el-row :gutter="20">
                    <el-col :span="12">
                        <el-form-item label="跟踪人" prop="name">
                            <el-input
                                v-model="traceForm.name"
                                placeholder="请输入"
                            ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="跟踪内容" prop="content">
                            <el-input
                                v-model="traceForm.content"
                                placeholder="请输入"
                            ></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="12">
                        <el-form-item label="跟踪时间" prop="happenTime">
                            <el-date-picker
                                type="date"
                                placeholder="选择日期/时间"
                                value-format="yyyy-MM-dd HH:mm:ss"
                                style="width: 100%"
                                v-model="traceForm.happenTime"
                                clearable
                            >
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="约谈方式" prop="interviewMethod">
                            <el-select
                                v-model="traceForm.interviewMethod"
                                placeholder="请选择"
                                style="width: 100%"
                            >
                                <el-option
                                    v-for="item in dict_track_way"
                                    :key="item.dictCode"
                                    :label="item.dictLabel"
                                    :value="item.dictValue"
                                >
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="cancaeClicked">取 消</el-button>
                <el-button type="primary" @click="subtrace()">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { campConsultPage, getNameList, campConsultCreate, updateGuestStatus, campTrackLogInsert, campTrackLogList, campTrackLogEdit, campTrackLogDel } from '@/http/api'

export default {
  data () {
    return {
      traceForm: {
        campConsultId: '',
        name: '',
        content: '',
        happenTime: '',
        interviewMethod: null
      },
      traceList: [],
      campConsultId: '',
      traceFormRules: {
        name: [{ required: true, message: '请输入', trigger: 'blur' }],
        content: [{ required: true, message: '请输入', trigger: 'blur' }],
        happenTime: [{ required: true, message: '请选择', trigger: 'change' }],
        interviewMethod: [{ required: true, message: '请选择', trigger: 'change' }]
      },
      traceDialogVisable: false,
      changeStateRules: {
        guestStatus: [{ required: true, message: '请选择', trigger: 'change' }]
      },
      dict_guestStatus: this.$userInfo.dict_guestStatus(),
      dict_track_way: this.$userInfo.dict_interviewMethod(),
      changeStateDialog: false,
      stateModel: {},
      addInfoRules: {},
      addInfoModel: {},
      addTraceDialogVisable: false,
      addInfoDialog: false,
      dataList: [],
      campList: [],
      queryForm: {
        phone: '',
        name: '',
        pageNum: 1,
        pageSize: 10
      },
      total: 0
    }
  },
  created () {
    this.getCamList()
  },
  methods: {
    goBack () {
      this.$router.back()
    },
    tableRowClassName ({ row, rowIndex }) {
      if (rowIndex % 2 === 1) {
        return 'success-row'
      }
      return ''
    },
    getCamList () {
      getNameList().then((res) => {
        this.campList = res.data
        this.getDataList()
      }).catch((err) => {
        console.log('err', err)
      })
    },
    getDataList () {
      campConsultPage(this.queryForm).then((res) => {
        this.dataList = res.data.list
        this.total = res.data.total
      }).catch((err) => {
        console.log('err', err)
      })
    },

    queryReset () {
      this.$refs.queryFormRef.resetFields()
      this.getDataList()
    },
    handleSizeChange (newSize) {
      this.queryForm.pageSize = newSize
      this.getDataList()
    },
    handleCurrentChange (newCurPage) {
      this.queryForm.pageNum = newCurPage
      this.getDataList()
    },
    // 保存咨询信息
    addCampersClick () {
      campConsultCreate(this.addInfoModel).then((res) => {
        this.addInfoDialog = false
        this.$message.success('保存成功')
        this.getDataList()
      }).catch((err) => {
        console.log('err', err)
      })
    },
    // 修改客单咨询状态
    editStateDetail (rowData) {
      this.stateModel.id = rowData.id
      this.changeStateDialog = true
    },
    changeClicked () {
      updateGuestStatus(this.stateModel).then((res) => {
        this.changeStateDialog = false
        this.$message.success('修改成功')
        this.getDataList()
      }).catch((err) => {
        console.log('err', err)
      })
    },
    // 查看跟踪记录点击
    addGuestStatus (rowData) {
      this.traceForm.campConsultId = rowData.id
      this.campConsultId = rowData.id
      this.getTraceList(rowData.id)
    },
    // 获取跟踪记录
    getTraceList (campConsultId) {
      campTrackLogList({ campConsultId: campConsultId }).then((res) => {
        this.traceList = res.data
        this.traceDialogVisable = true
      }).catch((err) => {
        console.log('err', err)
      })
    },
    // 新增跟踪记录点击事件
    addTraceRecord () {
      this.traceDialogVisable = false
      this.addTraceDialogVisable = true
    },
    // 取消编辑和新增
    cancaeClicked () {
      this.traceDialogVisable = true
      this.addTraceDialogVisable = false
    },
    // 编辑跟踪记录
    editCampDetail (rowData) {
      this.traceForm = rowData
      this.traceForm.campConsultId = this.campConsultId
      this.traceDialogVisable = false
      this.addTraceDialogVisable = true
    },
    subtrace () {
      if (this.traceForm.campConsultId) {
        campTrackLogEdit(this.traceForm).then((res) => {
          this.$message.success('添加成功')
          this.traceDialogVisable = true
          this.addTraceDialogVisable = false
          this.getTraceList(this.traceForm.campConsultId)
        }).catch((err) => {
          console.log('err', err)
        })
      } else {
        campTrackLogInsert(this.traceForm).then((res) => {
          this.$message.success('添加成功')
          this.traceDialogVisable = true
          this.addTraceDialogVisable = false
          this.getTraceList(this.traceForm.campConsultId)
        }).catch((err) => {
          console.log('err', err)
        })
      }
    },
    // 是否删除该记录
    showDeleteCampTip (rowId) {
      this.$confirm('是否确认删除该记录?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        console.log('rowId', rowId)
        campTrackLogDel(rowId).then((res) => {
          this.getTraceList(this.campConsultId)
        }).catch((err) => {
          console.log('返回的err', err)
        })
      })
    },
    campNameFormatter (row, column, cellValue) {
      var temp = '未知'
      for (let index = 0; index < this.campList.length; index++) {
        const element = this.campList[index]
        console.log(element.id, cellValue)
        if (element.id === cellValue + '') {
          return element.name
        }
      }
      return temp
    },
    interviewMethodFormatter (row, column, cellValue) {
      var temp = '未知'
      for (let index = 0; index < this.dict_track_way.length; index++) {
        const element = this.dict_track_way[index]
        console.log(element.id, cellValue)
        if (element.dictValue === cellValue) {
          return element.dictLabel
        }
      }
      return temp
    }
  }
}
</script>

<style lang="less" scoped>
.el-table .warning-row {
    background: oldlace;
}

.el-table .success-row {
    background: #f0f9eb;
}
</style>
